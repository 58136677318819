import React, { useEffect } from 'react';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';

import { pageView } from '../helpers/googleTag';
import styles from '../styles/pages/error.module.scss';

const Error = () => {
  const { gtmPageViewAuthInfo } = useAuthContext();

  useEffect(() => {
    pageView('404', '/ga-virtual/error/404', 'error', gtmPageViewAuthInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.errorContainer}>
      <h2 className={styles.header}>Ooops something went wrong!</h2>
      <p>This page cannot be found.</p>
    </div>
  );
};

export const getStaticProps = () => ({
  props: {
    pageTitle: 'Page not found',
  },
  revalidate: 60 * 30,
});

export default Error;
